<template>
  <div class='pa-6'>
    <v-sheet v-if="!isKarte" tile height='60' class='d-flex'>
      <h2>利用履歴</h2>
    </v-sheet>
    <v-data-table
      :headers="headers"
      :items="histories"
      sort-by="name"
      :items-per-page='15'
      :loading='loading'
      loading-text='読み込み中です'
      no-data-text='対象のデータがありません'
    >
      <template v-slot:item.isCancel="{ item }">
        <v-chip
          v-if="item.isCancel"
          class="ma-2"
          color="error"
        >
          キャンセル済み
        </v-chip>
      </template>
      <template v-slot:item.instructor='{ item }'>
        {{ item.instructor.name }}
        <p v-if= 'item.instructor.name !== "なし"'
          class='mb-0 text--secondary v-messages'
          style='line-height: 1.5;'
        >
        ({{ item.instructor.nameKana }})
        </p>
      </template>
      <template v-slot:item.shop="{ item }">
      {{ item.shop.name }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import util from '../util';

export default {
  name: 'attendance-history',
  props: {
    userId: {
      type: String,
      default: '',
    },
    isKarte: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { text: '', sortable: false, value: 'isCancel', width: '50px' },
      { text: '利用日時', sortable: false, value: 'date' },
      { text: '店舗', sortable: false, value: 'shop' },
      { text: '種別', sortable: false, value: 'type' },
      { text: '担当コーチ', sortable: false, value: 'instructor' },
      { text: '打席', sortable: false, value: 'category' },
    ],
    shops: [],
    editedIndex: -1,
    editedItem: {},
    histories: [],
    loading: false,
  }),

  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
  },

  mounted() {
    this.initialize();
  },
  watch: {
    async userId() {
      await this.initialize();
    },
  },
  methods: {
    async initialize() {
      // this.shops = await util.callCloudFunction('getShop');
      const param = {
        uid: this.userId ? this.userId : this.userInfo.uid,
        type: 'next',
        refTime: '',
      };
      this.loading = true;
      const res = await util.callCloudFunction('getHistory', param);
      this.histories = res.history;
      this.loading = false;
    },
    chageTime(item) {
      return moment(item, 'H').format('HH:mm');
    },
    // shopIdToShopName(id) {
    //   return this.shops.find(v => v.id === id).name;
    // },
  },
};
</script>
<style>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
